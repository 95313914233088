import '../sass/index.scss';

import $ from 'jquery';
import { menuOpen } from './menu';
import { scrollHeader } from './scroll_header';
import { smoothScroll } from './smooth_scroll';

menuOpen();
scrollHeader();
smoothScroll();

$(() => {
  console.log('hello world!');
});
