import $ from 'jquery';
export function menuOpen() {
const openedClass = 'is-opened';

  $('.js-menu-trigger').on('click', function(){
    if(!$(this).hasClass('animated')){
      $("body").toggleClass(openedClass).addClass('animated');
      setTimeout(function(){
        $('.animated').removeClass('animated');
      }, 400);
    }
  });
}
