import $ from 'jquery';
export function scrollHeader() {
  let flag = false;
  const scrolledClass = 'is-scrolled';

  // スクロール時のヘッダー
  $(window).on('scroll', function() {
      if($(window).scrollTop() > 850){
        if(!flag) {
          flag = true;
          $('body').addClass(scrolledClass);
        }
      } else {
        if(flag) {
          flag = false;
          $('body').removeClass(scrolledClass);
        }
      }
  });
}