import $ from 'jquery';
export function smoothScroll() {

  const scroll = $('.js-smooth-scroll');
  const _touch = ('ontouchstart' in document) ? 'touchstart' : 'click';

  scroll.on('click', function(event){
    console.log('menuclick');
    event.preventDefault();
    const headerHeight = $('.l-header').outerHeight();
    const windowWidth =$('body').width();
    const speed = 300;
    let href= $(this).attr("href");
    let target = $(href === "#" || href === "" ? 'html' : href);
    let position = target.offset().top - headerHeight;
    let basicPosition = target.offset().top;
    if($('body').hasClass('is-opened')) {
      $('body').removeClass('is-opened');
    }
    $('html, body').animate({scrollTop:position}, speed, 'swing');
    return false;
  });
}
